<template>
  <div class="order-complete">
    <div class="cart-review-title">
      <h1 class="page-title">Your order is complete.</h1>

      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <div class="page-content text-center">

            <CheckCircleBigIcon />

            <h3>Soon you will receive the tickets in your email.</h3>
            <p>Check also in your spam box.</p>

            <p>
              Click <router-link to="/">here</router-link> to continue shopping.
            </p>
          </div>
        </div>

      </div>
      
    </div>
  </div>



</template>

<script>
import CheckCircleBigIcon from "@/assets/icons/check-circle-big.svg";
export default {
  name: 'OrderComplete',
  metaInfo: {
    title: 'Your order is complete.',
  },
  components: {
    CheckCircleBigIcon
  },
  data() {
    return {
    }
  },
  methods: {
    onClickCreateAccount() {
      // this.$router.push(`/register`);
    }
  }
}
</script>

<style lang="scss">
.order-complete {
  .cart-review-title {
    .page-title {
      margin: 6rem 0 0rem;
      font-size: 76px;
      font-weight: 700;
      text-align: center;
    }

  }

  .page-content {
    h3 {
      font-weight: 700;
      font-size: 29px;
      line-height: 1.2;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      margin-top: 0;

      &:last-child {
        margin-top: 2rem;

      }
    }

    svg {
      margin: 32px 0;
    }
  }
}
</style>
